import axios from "axios";
import { DOMAIN, HOST } from "../../../utils/constants/Config";

const urlPrefix: string = `${DOMAIN}/${HOST}/sport-tickets/api/v1`;

export function getUserBets(reqObj: {})
{
    return axios.post(`${urlPrefix}/tickets/sport/aff_external`, reqObj, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}

export function getUserBetById(betId: string)
{
    return axios.get(`${urlPrefix}/tickets/sport/aff_external/${betId}`, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}