import React from "react";
import { IUserBonusConfig } from "../../../../../typings/bonuses/IUserBonuses";

import styles from "./bonus-history-item.module.scss";

interface IBonusHistoryItemProps
{
    bonus: IUserBonusConfig;
}

const BonusHistoryItem: React.FC<IBonusHistoryItemProps> = ({ bonus }): React.ReactElement =>
{
    return <div className={styles.item}>
        <p><span className={styles.bold}>{bonus?.alias}</span></p>
        <p>Status: <span>{bonus.status}</span></p>
        <p>Type: <span>{bonus.type}</span></p>
        <p>Issued: <span>{new Date(bonus.created_at).toLocaleString("en-GB")}</span></p>
        <p>Closed: <span>{new Date(bonus.closed_at).toLocaleString("en-GB")}</span></p>
        <p>Amount: <span>{bonus.bonus_amount} {bonus.currency}</span></p>
        {bonus.type === "sport_bonus" && <p>Progress: <span>{bonus?.wager_report?.placed?.amount}/{bonus.needed_amount} Wagered</span></p>}
        <p>Payout: <span>{bonus.payout_amount} {bonus.currency}</span></p>
    </div>;
};

export default BonusHistoryItem;