import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import styles from "./bonuses.module.scss";

const Bonuses: React.FC = ({}): React.ReactElement =>
{
    const params = useParams();
    const navigate = useNavigate();

    return <div className={styles.container}>
        <div className={styles.buttons}>
            <button onClick={() => navigate(`/${params.siteId}/${params.userId}/bonuses/current`)}>Current</button>
            <button onClick={() => navigate(`/${params.siteId}/${params.userId}/bonuses/history`)}>History</button>
            <button onClick={() => navigate(`/${params.siteId}/${params.userId}/bets`)}>Bets</button>
        </div>
        <Outlet />
    </div>;
};

export default Bonuses;