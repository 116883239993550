import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CurrentBonusItem from "./item/CurrentBonusItem";
import { IUserBonusConfig } from "../../../../typings/bonuses/IUserBonuses";
import { IItemsData, IPagesData } from "../../../../typings/pages/IPagesData";
import { getUserBonuses } from "../../../services/rest/bonus/BonusService";
import Pagination from "../../pagination/Pagination";

import styles from "./current-bonuses.module.scss";

const CurrentBonuses: React.FC = ({}): React.ReactElement =>
{
    const params = useParams();
    const [bonuses, setBonuses] = useState<IUserBonusConfig[]>([]);
    const [itemsData, setItemsData] = useState<IItemsData>();
    const [pagesData, setPagesData] = useState<IPagesData>();

    const onPageChange: (page: number) => void = (page): void =>
    {
        const reqObj = {
            page: page,
            limit: 25,
            status: ["open", "active", "pre-active"],
            sort: "-_id",
            user_id: params.userId,
            site_id: params.siteId
        };

        getUserBonuses(reqObj).then(resp =>
        {
            setBonuses(resp.data);
            setItemsData(resp.items);
            setPagesData(resp.pages);
        }).catch(error =>
        {
            console.log("error", error);
        });
    };

    useEffect(() =>
    {
        const reqObj = {
            page: 1,
            limit: 25,
            status: ["open", "active", "pre-active"],
            sort: "-_id",
            user_id: params.userId,
            site_id: params.siteId
        };

        getUserBonuses(reqObj).then(resp =>
        {
            setBonuses(resp.data);
            setItemsData(resp.items);
            setPagesData(resp.pages);
        }).catch(error =>
        {
            console.log("error", error);
        });
    }, []);

    return <div className={styles.container}>
        <div className={styles.items}>
            {bonuses.length > 0 ? bonuses.map((item) => <CurrentBonusItem bonus={item}/>) : <div>No Bonuses Found</div>}
        </div>
        <Pagination itemsData={itemsData} pagesData={pagesData} onPageChange={onPageChange}/>
    </div>;
};

export default CurrentBonuses;