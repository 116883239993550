import React from "react";
import { IUserBonusConfig } from "../../../../../typings/bonuses/IUserBonuses";

import styles from "./current-bonus-item.module.scss";

interface ICurrentBonusItemProps
{
    bonus: IUserBonusConfig;
}

const CurrentBonusItem: React.FC<ICurrentBonusItemProps> = ({ bonus }): React.ReactElement =>
{
    let placed: number = Math.round(100 * bonus?.wager_report?.placed?.amount / bonus?.needed_amount);

    if (placed > 100)
    {
        placed = 100;
    }

    let settled: number = Math.round(100 * bonus?.wager_report?.settled?.amount / bonus?.needed_amount);

    if (settled > 100)
    {
        settled = 100;
    }

    return <div className={styles.item}>
        <p><span className={styles.bold}>{bonus?.alias}</span></p>
        <p>Type: <span className={styles.bold}>{bonus.type}</span></p>
        <p>Amount: <span className={styles.bold}>{bonus.amount} {bonus.currency}</span></p>
        {bonus.sport_bonus && <div className={styles.wager}>
                <span>{bonus.sport_bonus?.wager}x Wager</span><span><span>{bonus?.wager_report?.placed?.amount}/{bonus.needed_amount}</span><span
            className={styles.wagered}>Wagered</span></span></div>}
        {bonus.sport_bonus && <div className={styles.progress}>
            <div className={styles.placed}
                 style={{ width: `${placed}%` }}/>
            <div className={styles.settled}
                 style={{ width: `${settled}%` }}/>
        </div>}
    </div>;
};

export default CurrentBonusItem;