import axios from "axios";
import { DOMAIN, HOST } from "../../../utils/constants/Config";

const urlPrefix: string = `${DOMAIN}/${HOST}/bonus/api/v1`;

export function getUserBonuses(reqObj: {})
{
    return axios.post(`${urlPrefix}/user_bonus/aff_external`, reqObj, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}