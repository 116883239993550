import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./home.module.scss";

const Home: React.FC = ({}): React.ReactElement =>
{
    const navigate = useNavigate();
    const [value, setValue] = useState("");

    return <div className={styles.container}>
        <form onSubmit={() => navigate(`${value}/bets`)}>
            <p>User Id</p>
            <input value={value} onChange={(event) => setValue(event.target.value)}/>
            <input type="submit" value="Next"/>
        </form>
    </div>;
};

export default Home;