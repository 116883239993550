import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Bets from "../components/bets/Bets";
import BetDetails from "../components/bet-details/BetDetails";
import Home from "../components/home/Home";
import CurrentBonuses from "../components/bonuses/current/CurrentBonuses";
import BonusesHistory from "../components/bonuses/history/BonusesHistory";
import Bonuses from "../components/bonuses/Bonuses";

const Router = () =>
{
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path=":siteId" element={<Home/>}/>
                <Route path=":siteId/:userId/bets" element={<Bets/>}/>
                <Route path=":siteId/:userId/bets/:betId" element={<BetDetails/>}/>
                <Route path=":siteId/:userId/bonuses" element={<Bonuses />}>
                    <Route path="current" element={<CurrentBonuses/>}/>
                    <Route path="history" element={<BonusesHistory/>}/>
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>;
};

export default Router;
