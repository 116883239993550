import React from "react";
import Router from "./router/Router";

import "./App.scss";

function App()
{
    return (
        <div>
            <Router/>
        </div>
    );
}

export default App;
