import React from "react";
import { IItemsData, IPagesData } from "../../../typings/pages/IPagesData";

import styles from "./pagination.module.scss";

interface IPaginationProps
{
    itemsData: IItemsData;
    pagesData: IPagesData;
    onPageChange: (page) => void;
}

const Pagination: React.FC<IPaginationProps> = ({ itemsData, pagesData, onPageChange }): React.ReactElement =>
{
    return <div className={styles.pagination}>
        {itemsData && <div>{`Show ${itemsData.begin} - ${itemsData.end} from ${itemsData.total}`}</div>}
        {pagesData && <div className={styles.buttons}>
            <button disabled={!pagesData.hasPrev} onClick={() => onPageChange(pagesData.current - 1)}>Prev</button>
            <span>{pagesData.current}</span>
            <button disabled={!pagesData.hasNext} onClick={() => onPageChange(pagesData.current + 1)}>Next</button>
        </div>}
    </div>;
};

export default Pagination;