import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { IBetTicketData } from "../../../../typings/my-bets/IBetTicketData";

import styles from "./bet-item.module.scss";

interface IBetItemProps
{
    data: IBetTicketData;
}

const BetItem: React.FC<IBetItemProps> = ({ data }): React.ReactElement =>
{
    const navigate = useNavigate();

    const getName: () => string = (): string =>
    {
        switch (data.type)
        {
            case "single":
                return data.selections[0]?.event?.name || data.selections[0]?.event?.alias;
            case "accumulator":
                return `Multiple ${data.selections.length}`;
            case "system":
                return `System ${data.system}/${data.selections.length}`;
            default:
                return "";
        }
    };

    const getBonusType: () => string = (): string =>
    {
        switch (data.bonus_info?.type)
        {
            case "free_bet":
                return "Freebet";
            case "sport_bonus":
                return "Sport Bonus";
            case "express":
                return "Express Bonus";
            default:
                return "";
        }
    };

    return <div className={styles.item} onClick={() => navigate(data._id)}>
        <p><span className={styles.bold}>{getName()}</span></p>
        <p>Stake: <span className={styles.bold}>{data.stake}</span></p>
        <p>Odd: <span className={styles.bold}>{data.payout_info?.value}</span></p>
        <p className={styles.capitalize}>Status: <span
            className={clsx(styles.bold, styles[data.open ? "pending" : data.return_type])}>{data.open ? "pending" : data.return_type}</span>
        </p>
        <p>Possible Win: <span className={styles.bold}>{data.payout_info?.return}</span></p>
        <p>Return: <span className={styles.bold}>{data.return === undefined ? "--" : data.return}</span></p>
        <p>ID: <span>{data.short_id}</span></p>
        <p>Placement Date: <span className={styles.bold}>{data?.created_at ? new Date(data?.created_at).toLocaleString("en-GB") : "--"}</span></p>
        <p>Settlement Date: <span className={styles.bold}>{data?.closed_at ? new Date(data?.closed_at).toLocaleString("en-GB"): "--"}</span></p>
        {data.bonus_info &&
            <p className={styles.bonus}>{getBonusType()}: <span>{data.bonus_info?.name || data.bonus_info?.alias}</span></p>}
    </div>;
};

export default BetItem;