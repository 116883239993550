import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { IBetTicketData } from "../../../typings/my-bets/IBetTicketData";
import { getUserBetById } from "../../services/rest/my-bets/MyBetsService";

import styles from "./bet-details.module.scss";

const BetDetails: React.FC = ({}): React.ReactElement =>
{
    const params = useParams();
    const [bet, setBet] = useState<IBetTicketData>();

    const getName: () => string = (): string =>
    {
        switch (bet.type)
        {
            case "single":
                return "Single";
            case "accumulator":
                return "Multiple";
            case "system":
                return `System ${bet.system}/${bet.selections.length}`;
            default:
                return "";
        }
    };

    const getBonusType: () => string = (): string =>
    {
        switch (bet.bonus_info?.type)
        {
            case "free_bet":
                return "Freebet";
            case "sport_bonus":
                return "Sport Bonus";
            case "express":
                return "Express Bonus";
            default:
                return "";
        }
    };

    useEffect(() =>
    {
        getUserBetById(params.betId).then(resp =>
        {
            setBet(resp.data);
        }).catch(error =>
        {
            console.log("error", error);
        });
    }, []);

    return bet ? <div className={styles.container}>
        <p className={styles.type}><span className={clsx(styles.bold, styles.capitalize)}>{getName()}</span></p>
        <p>Stake: <span className={styles.bold}>{bet.stake}</span></p>
        <p>Odd: <span className={styles.bold}>{bet.payout_info?.value}</span></p>
        <p className={styles.capitalize}>Status: <span
            className={clsx(styles.bold, styles[bet.open ? "pending" : bet.return_type])}>{bet.open ? "pending" : bet.return_type}</span>
        </p>
        <p>Bet Date: <span
            className={styles.bold}>{new Date(bet.created_at).toLocaleString("en-GB")}</span></p>
        <p>Possible Win: <span className={styles.bold}>{bet.payout_info?.return}</span></p>
        <p>Return: <span className={styles.bold}>{bet.return === undefined ? "--" : bet.return}</span></p>
        <p>ID: <span>{bet.short_id}</span></p>
        {bet.bonus_info &&
            <p className={styles.bonus}>{getBonusType()}: <span>{bet.bonus_info?.name || bet.bonus_info?.alias}</span>
            </p>}
        <div className={styles.selections}>
            <p className={styles.bold}>Selections</p>
            {bet.selections.map(selection => <div key={selection.event?.id} className={styles.selection}>
                <p className={styles.bold}>{selection.sport?.name || selection.sport?.alias}</p>
                <p className={styles.bold}>{selection.region?.name || selection.region?.alias}, {selection.tournament?.name || selection.tournament?.alias}</p>
                <p className={styles.bold}>{selection.event?.name || selection.event?.alias}</p>
                {selection?.event?.type !== "outright" && <p>Date: <span
                    className={styles.bold}>{new Date(selection.date?.current * 1000).toLocaleString("en-GB")}</span>
                </p>}
                <p>Market: <span className={styles.bold}>{selection.market?.name || selection.market?.alias}</span></p>
                <p>Pick: <span className={styles.bold}>{selection.odd?.name || selection.odd?.alias}</span></p>
                <p>Odd: <span className={styles.bold}>{selection.odd?.value || selection.odd?.value}</span></p>
                <p className={styles.capitalize}>Status: <span
                    className={clsx(styles.bold, styles[selection.result ? selection.result?.type : "pending"])}>{selection.result ? selection.result?.type : "pending"}</span>
                </p>
                {selection.match_info?.before && <p>Score at the time of placing
                    bet: {selection.match_info?.before?.score} (<>{selection.match_info?.before?.scores?.map((score, index) =>
                            <span key={score.period}>
                        {score.score}
                                {index !== selection.match_info?.before?.scores?.length - 1 && ", "}
                    </span>
                    )}</>
                    )
                </p>}
                {selection.match_info?.after && <p>Score at the time of
                    settlement: {selection.match_info?.after?.score} (<>{selection.match_info?.after?.scores?.map((score, index) =>
                            <span key={score.period}>
                        {score.score}
                                {index !== selection.match_info?.after?.scores?.length - 1 && ", "}
                    </span>
                    )}</>
                    )
                </p>}
            </div>)}
        </div>
    </div> : null;
};

export default BetDetails;