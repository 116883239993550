import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { IBetTicketData } from "../../../typings/my-bets/IBetTicketData";
import { getUserBets } from "../../services/rest/my-bets/MyBetsService";
import BetItem from "./item/BetItem";
import { IItemsData, IPagesData } from "../../../typings/pages/IPagesData";
import Pagination from "../pagination/Pagination";

import styles from "./bets.module.scss";

const Bets: React.FC = ({}): React.ReactElement =>
{
    const params = useParams();
    const navigate = useNavigate();
    const [bets, setBets] = useState<IBetTicketData[]>([]);
    const [itemsData, setItemsData] = useState<IItemsData>();
    const [pagesData, setPagesData] = useState<IPagesData>();
    const [stakeType, setStakeType] = useState("all");

    const onPageChange: (page: number) => void = (page): void =>
    {
        const reqObj = {
            page: page,
            limit: 25,
            sort: "-_id",
            user_id: params.userId,
            site_id: params.siteId,
            status: ["approved"],
            create_from: moment().subtract(90, "days").endOf("day").unix(),
            create_to: moment().add(1, "days").startOf("day").unix()
        };

        if (stakeType !== "all")
        {
            reqObj["stake_type"] = stakeType;
        }

        getUserBets(reqObj).then(resp =>
        {
            setBets(resp.data);
            setItemsData(resp.items);
            setPagesData(resp.pages);
        }).catch(error =>
        {
            console.log("error", error);
        });
    };

    useEffect(() =>
    {
        const reqObj = {
            page: 1,
            limit: 25,
            sort: "-_id",
            user_id: params.userId,
            site_id: params.siteId,
            status: ["approved"],
            create_from: moment().subtract(90, "days").endOf("day").unix(),
            create_to: moment().add(1, "days").startOf("day").unix()
        };

        if (stakeType !== "all")
        {
            reqObj["stake_type"] = stakeType;
        }

        getUserBets(reqObj).then(resp =>
        {
            setBets(resp.data);
            setItemsData(resp.items);
            setPagesData(resp.pages);
        }).catch(error =>
        {
            console.log("error", error);
        });
    }, [stakeType]);

    return <div className={styles.container}>
        <div className={styles.selector}>
            <select onChange={(event) => setStakeType(event.target.value)}>
                <option value="all">All</option>
                <option value="free_bet">Freebet</option>
                <option value="bonus">Sport Bonus</option>
            </select>
            <button onClick={() => navigate(`/${params.siteId}/${params.userId}/bonuses/current`)}>Bonuses</button>
        </div>
        <div className={styles.items}>
            {bets.map(item => <BetItem key={item._id} data={item}/>)}
        </div>
        <Pagination itemsData={itemsData} pagesData={pagesData} onPageChange={onPageChange}/>
    </div>;
};

export default Bets;